import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchTemplates(ctx, params) {
      return useJwt.getTemplates(params).then(response => response);
    },
    createTemplate(ctx, data) {
      return useJwt.createTemplate(data).then(response => response);
    },
    updateTemplate(ctx, data) {
      return useJwt.updateTemplate(data).then(response => response);
    },
    previewTemplate(ctx, id) {
      return useJwt.previewTemplate(id).then(response => response);
    },
    deleteTemplates(ctx, data) {
      return useJwt.deleteTemplates(data).then(response => response);
    },
  },
};
